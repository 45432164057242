// Here you can add other styles
.sidebar .nav {
    width: 250px;}
    @media (min-width: 992px)
   {
    .sidebar-fixed .sidebar {
      
        width: 250px;}}
        .sidebar .sidebar-nav {
            width: 250px;
        }
        @media (min-width: 992px)
      {
        html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
            margin-left: 250px;
        }}